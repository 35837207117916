<script>
//import vue2Dropzone from "vue2-dropzone";
//import Multiselect from "vue-multiselect";
import {
  required,
  email,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import UserService from "@/services/UserService";
//import moment from "moment";

const userService = new UserService();

//import "vue2-dropzone/dist/vue2Dropzone.min.css";
//import "vue-multiselect/dist/vue-multiselect.min.css";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Usuario",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    //vueDropzone: vue2Dropzone,
    //Multiselect,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Usuario",
      infoPage:{
        roles:[{id:1, text:'Administrador'}, {id:2, text:'Recepción'},{id:3, text:'Pagador'}],
        view: 'add',
        routeBack: '/usuarios/list'
      },

  
      objectData:{
        name: null,
        password: null,
        role: 1,
        email: null,
        id: null
      },
      

      items: [
        {
          text: "Inicio",
        },
        {
          text: "Agregar usuario",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      value1: null,
      submitted: false,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
    };
  },
  validations: {
      objectData: {
        name: {
          required,
        },
        /*password: {
          required,
        },*/
        role: {
          required,
        },
        email:{
          required,
          email
        }
      },
    },
  middleware: "authentication",
  created(){

    this.chargeItems();
  },
 methods: {

    chargeItems(){

      if(this.$route.name==='usuariosEdit'){
        this.infoPage.view = 'edit';
         
        userService.getById(this.$route.params.id).then(async response=>{
          let data = response.data.user;
         for(let prop in data){
           this.objectData[prop] = data[prop];
         }
          //console.log(response.data.client);
          /*let params = {
            client_id: this.$route.params.id
          }

         /* let responseItems = await accountService.getList(params);
          
          this.accounts = responseItems.data.accounts;*/


        });
      }
      
      

    },
    // eslint-disable-next-line no-unused-vars
    async formSubmit() {
      //console.log('enviado', this.$v);
      this.submitted = true;
      if(this.$v.$error===false &&  this.$v.$invalid===false){
        console.log('validado');
        let params = {...this.objectData}
        
        try{
          if( this.infoPage.view === 'edit'){
            await userService.update(params).then(requestResponse => {
              if(requestResponse.data.code===200){
                requestResponse.data.user;
                this.successForm(this.infoPage.routeBack);
              }else{
                this.errorForm(requestResponse.data.message);
              }
            });
          }else{
            delete params.id;
            userService.create(params).then(requestResponse => {
              if(requestResponse.data.code===200){
                requestResponse.data.user;
                this.successForm(this.infoPage.routeBack);
              }else{
                this.errorForm(requestResponse.data.message);
              }
            });
          }
        }catch(e){
          console.log(e);
        }
      }
      
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    errorForm(msg){
      Swal.fire("¡Operación cancelada!", msg, "warning")
    },
    successForm(route){
      Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
       this.$router.push({
            path: route,
          });
      });
    },
    cancelForm(){
      this.$router.push({
        path: this.infoPage.routeBack,
      });
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },


};
</script>

<template>
  <Layout>
    <form  class="needs-validation" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
      <div class="col-lg-12">
        <div id="addproduct-accordion" >
          <div class="card">
           
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Datos generales</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completar información del usuario
                    </p>
                  </div>
                 
                </div>
              </div>
            
            <div
            >
              <div class="p-4 border-top">
                
                  
                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Nombre(s)</label>
                        <input
                          id="name"
                          v-model="objectData.name"
                          type="text"
                          class="form-control"
                          placeholder="Nombre"
                          :class="{
                            'is-invalid': submitted && $v.objectData.name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.objectData.name.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.name.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                       <div class="mb-3">
                        <label for="name">Rol</label>
                        <select class="form-control select2" v-model="objectData.role"  :class="{
                            'is-invalid': submitted && $v.objectData.role.$error,
                          }">
                        <option :value="role.id" v-for="role in infoPage.roles" :key="role.id" v-text="role.text"></option>
                        </select>



                     
                        <div
                          v-if="submitted && $v.objectData.role.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.role.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>



                        
                    </div>
                  </div>




                 
                  
                  <div class="row">
                    <div class="col-md-6">
                  
                  
                      <div class="mb-3">
                        <label>Correo electrónico</label>
                          <input
                            v-model="objectData.email"
                            type="email"
                            name="email"
                            class="form-control"
                            :class="{
                              'is-invalid': submitted && $v.objectData.email.$error,
                            }"
                            placeholder="Ingresar Email"
                          />
                          <div v-if="submitted && $v.objectData.email.$error" class="invalid-feedback">
                            <span v-if="!$v.objectData.email.required"
                              >Campo requerido.</span>
                            <span v-if="!$v.objectData.email.email"
                              >Añadir un correo eléctronico válido.</span>
                          </div>
                      </div>
                    </div>
                    
                    <div class="col-md-6">
                      <div class="form-group mb-3">
                          <label>Contraseña</label>
                          <input
                            v-model="objectData.password"
                           
                            type="password"
                            class="form-control"
                          :class="{
                            'is-invalid': submitted && $v.objectData.password.$error,
                          }"
                          />
                        
                           <div
                          v-if="submitted && $v.objectData.password.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.objectData.password.required"
                            >Campo requerido.</span
                          >
                        </div>
                      </div>
                       
                    </div>
                  
                  
                  </div>
                


                  
                  
              </div>
            </div>
          </div>

        
          
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger" @click.prevent="cancelForm()">
          <i class="uil uil-times me-1"></i> Cancelar
        </a>
        <button   class="btn btn-success ms-1" @click.prevent="formSubmit">
          <i class="uil uil-file-alt me-1"></i> Guardar
        </button>
      </div>
      <!-- end col -->
    </div>
    </form>
  </Layout>
</template>
